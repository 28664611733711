



















































































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  //   @Prop({ default: {} }) nft
  @Prop({ default: true }) showCode
  @Prop({ default: false }) showAttached
  @Prop({ default: false }) showAttach
  @Prop({ default: false }) showDetach
  @Prop({ default: true }) showAttributeLevel
  @Prop({ default: false }) showPopoLevel
  @Prop({ default: false }) showActiveNft
  @Prop({ default: [] }) decorators
  @Prop({ default: 'darken1' }) cardBackground
  @Prop({ default: false }) showNftType
  @Prop({ default: true }) showSilhouette
  @Prop({ default: () => ({}) }) nft

  popoStore = popoStore

  get nftColor() {
    switch (this.nft?.type) {
      case 'top':
        return 'color-top-nft'
      case 'bottom':
        return 'color-bottom-nft'
      case 'hair_accessory':
        return 'color-accessory1-nft'
      case 'prop_accessory':
        return 'color-accessory2-nft'
      default:
        return 'grey'
    }
  }
}
